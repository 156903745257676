import React from "react";
import shortid from  "shortid";

const EletiveSection4 = (props) => {
    return(
        <section className="section-4">
            <div className="container">
                <div className="title-block">
                    <div className="row">
                        <div className="col-lg-20">
                            <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="images-block-1">
                <div className="container">
                    <h2 className="h2 section-title normal-spacing">{props.subTitle}</h2>
                    {props.imageslist?.map((object, i) => {
                        return (
                            <div className="image-block" key={shortid.generate()}>
                                <img
                                    src={object.image1x?.sourceUrl}
                                    srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                    width={object.image1x?.width}
                                    alt={object.image1x?.altText}
                                />
                            </div>     
                        )
                    })}
                </div>
            </div>
            <div className="images-block-2">
                <div className="container">
                    <div className="row">
                        {props.imageslist2?.map((object, i) => {
                            return (
                                <div className={((i+1)%5 === 1) ? 'col-lg-24' : 'col-lg-12'} key={shortid.generate()}>
                                    <div className="image-block">
                                        <img
                                            src={object.image1x?.sourceUrl}
                                            srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                            width={object.image1x?.width}
                                            alt={object.image1x?.altText}
                                        />
                                    </div>
                                </div>     
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EletiveSection4;